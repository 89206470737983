.dashboard-nav {
    overflow: hidden;
}

.nav-pills-home {
    @include media-breakpoint-up(md) {
        margin-right: -10px !important;
    }

    .swiper-slide {
        width: auto;
        @include media-breakpoint-down(sm) {
            width: 85px;
            height: 85px;
            background: var(--primary);
            border-radius: map_get($spacers, 1);
        }
    }

    .js-widget-tab-inner {
        @include media-breakpoint-down(sm) {
            height: 100%;
            display: flex;
            padding: 0 6px;
            align-items: center;
        }
    }
}

.js-widget-tab {
    transition: color 0.25s ease-in-out, background 0.25s ease-in-out;
    @include rtl();

    @include media-breakpoint-down(sm) {
        border: 0;
        padding: 0;
        background: $transparent;
        color: $white;
        height: 21px;
        width: 100%;
        overflow: hidden;
        @include font-sizer(21px, 21px);
    }

    @include media-breakpoint-up(md) {
        padding: 4px map_get($spacers, 2);
        background: none;
        border: 0;
        border-radius: $global-border-radius;
        font-weight: $font600;
        cursor: pointer;
        color: var(--primary);
        @include font-sizer(21px, 21px);
    }

    &.active {
        background: var(--primary);
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &:hover {
        color: var(--secondary);
    }
}

.js-widget-tab-same-mobile {
    padding: 4px map_get($spacers, 2);
    background: none;
    border: 0;
    border-radius: $global-border-radius;
    font-weight: $font600;
    cursor: pointer;
    color: var(--primary);
    @include rtl();
    @include media-breakpoint-down(sm) {
        letter-spacing: -0.17px;
        @include font-sizer(17px, 17px);
    }
    @include media-breakpoint-up(md) {
        @include font-sizer(21px, 21px);
    }

    &.active,
    &:hover {
        background: var(--primary);
        color: $white;
    }
}

.nav-pills-item {
    margin-left: 4px;
}
