@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../functions";
@import "../utilities/typeUtils";
@import "../components/tabs";

.homepage-swiper {
    height: 150px;
    background-position: center center;
    position: relative;
    @include media-breakpoint-up(md) {
        height: auto;
    }

    .link-button-context {
        display: block;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: initial !important;
        }
    }
}

div {
    img {
        max-width: 100%;
    }
}

.homepage-promos {
    box-sizing: border-box;
    @include media-breakpoint-down(sm) {
        border-bottom: $netural-border-another;
    }

    p.small-caption {
        font-weight: $font-weight-semiBold;
        color: var(--secondary);
        @include font-sizer(29px, 108%);
        @include media-breakpoint-down(sm) {
            @include font-sizer(25px, 108%);
        }
    }

    p.big-caption {
        color: var(--primary);
        font-weight: $font-weight-bold;
        @include font-sizer(50px, 1);
        @include media-breakpoint-down(sm) {
            @include font-sizer(28px, 108%);
        }
    }

    .promo-wrapper {
        display: flex;
        justify-content: space-between;

        .promo-item {
            border-radius: map_get($spacers, 2);
            background: var(--primary);
            width: 208px;
            height: 428px;
            overflow: hidden;
            color: $white;
            transition: background 0.25s ease-in-out;

            @include media-breakpoint-down(sm) {
                width: 292px;
                height: 246px;
            }

            .promo-item-link {
                height: 246px;
                overflow: hidden;
                display: inline-block;
                transition: transform 0.25s ease-in-out;
                @include media-breakpoint-down(sm) {
                    height: 185px;
                }
            }

            .promo-item-image {
                width: 208px;
                height: 246px;
                transition: transform 0.25s ease-in-out;
                @include media-breakpoint-down(sm) {
                    width: 292px;
                    height: 185px;
                    object-fit: cover;
                    vertical-align: top;
                }
            }

            .caption {
                height: 51px;
                margin: map_get($spacers, 1) 0;
                overflow: hidden;
                padding: 0 4px;
                font-weight: $font600;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include font-sizer(24px);

                @include media-breakpoint-down(sm) {
                    height: 60px;
                    font-weight: $font700;
                    margin: 0;
                    @include font-sizer(25px, 25px);
                }
            }

            .promo-small-text {
                padding: 0 4px;
                @include font-sizer(17px);
            }

            &:hover {
                background: var(--primary-light);

                .promo-item-image {
                    transform: scale(1.08);
                }
            }
        }
    }

    .swiper {
        .swiper-pagination {
            bottom: 24px;
        }
    }
}

.homepage-article {
    box-sizing: border-box;

    .article-image-container {
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        .article-texting {
            position: absolute;
            right: 52px;
            top: 60px;
            width: 404px;

            .small-caption {
                font-weight: $font600;
                color: var(--secondary);
                margin-bottom: 8px;
                @include font-sizer(30px, 108%);
            }

            .big-caption {
                font-weight: $font700;
                color: var(--primary);
                @include font-sizer(51px, 90%);
            }
        }
    }

    .articles-place {
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
        }

        .article-column {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 360px;
            }

            .article-item {
                @include media-breakpoint-down(sm) {
                    border-top: $netural-border;
                    border-bottom: $netural-border;
                    margin-top: -1px;
                    position: relative;
                }

                .article-headLine {
                    font-weight: $font600;
                    margin-bottom: 12px;
                    color: var(--secondary);
                    @include font-sizer(30px, 108%);
                }

                .article-text {
                    margin-bottom: 0;
                    color: var(--primary);
                    @include font-sizer(20px, 104%);
                }
            }
        }
    }

    .collapse {
        @include media-breakpoint-up(md) {
            display: block !important;
        }
    }

    .article-button {
        position: relative;
        border-radius: 0;
        padding: map_get($spacers, 3);
        color: var(--primary-dark);
        @include font-sizer(25px, 25px);

        .svg-icon-second {
            transform: rotate(180deg);
            position: absolute;
            left: map_get($spacers, 3);
            top: 28px;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.collapsed {
            .svg-icon-second {
                transform: rotate(0deg);
                top: map_get($spacers, 3);
            }
        }
    }

    .small-caption {
        @include media-breakpoint-down(sm) {
            color: var(--secondary);
            font-weight: $font600;
            @include font-sizer(25px, 25px);
        }
    }

    .big-caption {
        @include media-breakpoint-down(sm) {
            color: var(--primary);
            font-weight: $font700;
            @include font-sizer(28px, 28px);
        }
    }
}

.personal-grow {
    .text-section {
        p {
            margin: 0 0 21px;
        }

        .caption {
            color: var(--secondary);
            font-weight: $font600;
            @include font-sizer(30px);
            @include media-breakpoint-down(sm) {
                margin: 0;
                padding: map_get($spacers, 3) 0 map_get($spacers, 2);
                font-weight: $font700;
                @include font-sizer(25px, 25px);
            }
        }

        .pg-big-text {
            font-weight: $font700;
            color: var(--primary);
            @include font-sizer(51px, 90%);
            @include media-breakpoint-down(sm) {
                margin: 0;
                padding-bottom: map_get($spacers, 2);
                @include font-sizer(27px, 90%);
            }
        }

        .pg-small-text {
            color: var(--primary);
            @include font-sizer(21px);
            @include media-breakpoint-down(sm) {
                margin: 0;
                padding-bottom: map_get($spacers, 2);
            }
        }
    }

    .personal-grow-image {
        border-radius: 15px;
        @include media-breakpoint-down(sm) {
            display: block;
            border-radius: 0;
            margin: 0 auto;
            vertical-align: top;
        }
    }
}

.homepage-reviews {
    background: var(--primary-light);

    .reviews-container {
        .caption {
            color: $white;
            font-weight: $font700;
            letter-spacing: -1px;
            @include font-sizer(50px, 1);
            @include media-breakpoint-down(sm) {
                @include font-sizer(25px, 25px);
            }
        }

        .review-wrapper {
            display: flex;
            justify-content: space-between;

            .review-tile {
                background: $white;
                width: 170px;
                height: 229px;
                padding: 8px 6px;
                border-radius: 15px;
                overflow: hidden;
                @include media-breakpoint-down(sm) {
                    width: 160px;
                    height: 160px;
                    padding: map_get($spacers, 3) map_get($spacers, 1);
                }

                .score {
                    width: 100%;
                    height: 15px;
                    background: $white;
                    overflow: hidden;

                    .icon-star {
                        fill: var(--secondary);
                        width: 15px;
                        height: 15px;
                        padding: 0;
                        margin: 0;
                    }
                }

                .review {
                    width: 100%;
                    color: var(--primary);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-clamp: 4;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    @include font-sizer(17px, 106%);
                    @include media-breakpoint-down(sm) {
                        @include font-sizer(15px, 15px);
                    }
                }

                .name {
                    width: 100%;
                    color: var(--secondary);
                    font-weight: $font600;
                    @include font-sizer(18px, 1);
                    @include media-breakpoint-down(sm) {
                        @include font-sizer(14.17px, 108%);
                    }
                }
            }
        }
    }
}

.legal-banner {
    p {
        margin: 0;
        border-top: $primary-border;
        padding: map_get($spacers, 2) 0 0;
        font-weight: $font400;
        @include font-sizer(17px, 106%);
    }
}

.widget-global-container {
    @include media-breakpoint-up(md) {
        background: $base-light;
    }
}

.home-widget-top-text {
    margin-bottom: map_get($spacers, 1);
    font-weight: $font700;
    color: var(--secondary);
    @include font-sizer(30px, 27px);
    @include rtl();
    @include media-breakpoint-up(md) {
        margin-bottom: map_get($spacers, 2);
        font-weight: $font600;
        letter-spacing: -0.6px;
        @include font-sizer(30px, 30px);
    }
}

.home-widget-title {
    margin-bottom: map_get($spacers, 5);
    color: var(--primary);
    font-weight: $font600;
    @include rtl();
    @include font-sizer(25px, 25px);
    @include media-breakpoint-up(md) {
        margin-bottom: map_get($spacers, 3);
        letter-spacing: -1.06px;
        font-weight: $font700;
        @include font-sizer(51px, 45.9px);
    }
}

.home-widget {
    overflow: hidden;
}
